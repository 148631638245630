<template>
  <tbody
    class="contents"
    autocomplete="off"
  >
    <tr
      class="contents focus-within:bg-gray-100"
      @keydown.enter="create"
    >
      <td
        v-for="(header, i) of headers.filter(header => header.key !== 'actions')"
        :key="i"
        class="flex bg-inherit mt-4 p-1 first:rounded-l-lg last:rounded-r-lg justify-start data-[align=right]:justify-end data-[align=center]:justify-center data-[space=true]:mr-2 group/header"
        :data-align="header.align"
        :data-space="header.space"
      >
        <slot
          :name="header.key"
          :error="errors[header.key]"
          :errors="errors"
        />
      </td>
      <td
        class="flex bg-inherit mt-4 p-1 pr-4 rounded-r-lg justify-center"
      >
        <slot name="actions">
          <div>
            <UButton
              icon="i-mingcute-add-circle-fill"
              size="xs"
              :padded="false"
              :ui="{ rounded: 'rounded-full' }"
              :color="hasValidatedErrors ? 'white' : 'primary'"
              variant="ghost"
              @click="create"
            />
            <UButton
              icon="i-mingcute-close-circle-fill"
              size="xs"
              :padded="false"
              :ui="{ rounded: 'rounded-full' }"
              color="primary"
              variant="soft"
              @click="reset"
            />
          </div>
        </slot>
      </td>
    </tr>
  </tbody>
</template>
<script setup lang="ts" generic="T extends Record<string, any>">
import type { Database } from '~/supabase/types'
import type { FormError } from '#ui/types'

const state = defineModel<Record<string, any>>({ required: true })

interface Props {
  headers: TableHeader<T>[]
  validate: (state: Record<string, any>) => FormError[]
  table: string
  createCallback?: (() => Promise<void>) | false
}
const props = withDefaults(defineProps<Props>(), {
  createCallback: false
})

const submittedFlag = ref(false)

const validatedErrors = computed(() => {
  if (submittedFlag.value) {
    const errors = props.validate(state.value)
    return errors
  }
  else return []
})

const hasValidatedErrors = computed(() => {
  return validatedErrors.value.length > 0
})

const errors = computed(() => {
  const paths = validatedErrors.value.map(error => error.path)
  return paths.reduce((result, path) => {
    result[path] = validatedErrors.value.filter(error => error.path === path).map(error => error.message).join(', ')
    return result
  }, {} as Record<string, string>) || {}
})

async function create() {
  submittedFlag.value = true
  if (!hasValidatedErrors.value) {
    const { $store } = useNuxtApp()
    const organization_id = $store.organizationId!
    state.value.organization_id = organization_id

    if (props.createCallback) {
      await props.createCallback()
    } else {
      const supabase = useSupabaseClient<Database>()
      const { error } = await supabase
        .from(props.table)
        .insert([state.value])
      if (error) {
        const { $toast } = useNuxtApp()
        $toast.error(error.message)
        return
      }
    }
    reset()
  }
}

function reset() {
  submittedFlag.value = false
  const { $store } = useNuxtApp()
  for (const key in state.value) {
    state.value[key] = undefined;
  }
  state.value.organization_id = $store.organizationId!
}
</script>

<style scoped>

</style>
